import deepCopy from '../utils/deepCopy';
import {fetchData} from './serverHandler';


class MailDataApi {
    getLabels() {
        return fetchData('labels', []);
    };

    getEmailsData() {
        return fetchData('emails', []);
    };
}

export const mailDataApi = new MailDataApi();


class MailApi {
    getEmails({systemLabel, customLabel}) {
        return new Promise(async (resolve, reject) => {
            try {
                // Initially we make a copy of all emails.
                // On a real server this will be different since there will be a real DB query.
                let emails = await mailDataApi.getEmailsData()
                let filteredEmails = [...emails];

                // If custom label is provided filter by it
                if (customLabel) {
                    let labels = await mailDataApi.getLabels();
                    let label = labels.find((_label) => _label.name === customLabel);

                    if (!label) {
                        // If label does not exist, we simply return an empty array
                        // because we need an ID to look for references
                        filteredEmails = [];
                    } else {
                        // If label exists, then filter the emails by the labelId
                        filteredEmails = filteredEmails.filter((mail) => mail.labelIds.includes(label.id));
                    }
                }

                switch (systemLabel) {
                    case 'drafts':
                    case 'inbox':
                    case 'sent':
                    case 'spam':
                    case 'trash':
                        filteredEmails = filteredEmails.filter((mail) => mail.folder === systemLabel);
                        break;
                    case 'starred':
                        filteredEmails = filteredEmails.filter((mail) => mail.isStarred);
                        break;
                    case 'important':
                        filteredEmails = filteredEmails.filter((mail) => mail.isImportant);
                        break;
                    default:
                        break;
                }

                resolve(deepCopy(filteredEmails));
            } catch (err) {
                console.error('[Mail Api]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }

    getEmail(emailId) {
        return new Promise(async (resolve, reject) => {
            try {
                // Find the mail
                let emails = await mailDataApi.getEmailsData();
                console.log("WHAT???");
                console.log(emails);

                const email = emails.find((_mail) => _mail.id === emailId);

                if (!email) {
                    reject(new Error('Email not found'));
                    return;
                }

                resolve(deepCopy(email));
            } catch (err) {
                console.error('[Mail Api]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }
}

export const mailApi = new MailApi();
