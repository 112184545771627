export function fetchData(dataKey, default_return_object) {
    return fetch(`https://us-central1-demostack-prod.cloudfunctions.net/revpower-json-data?data_key=${dataKey}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(jsonData => {
            console.log('API response:', jsonData);  // Inspect the structure
            const data = jsonData['data'];
            if (!data) {
                throw new Error(`No data found for key: ${dataKey}`);
            }
            return data;
        })
        .catch(error => {
            console.error(`Failed to fetch data for key: ${dataKey}`, error);
            return default_return_object
        });
}